.post-feed {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin-top: var(--spacing);
}

.post-card {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 var(--spacing);
  min-height: 300px;
  background: var(--body-fg) center center;
  background-size: cover;
  border-radius: var(--border-radius);
  box-shadow: var(--post-card-shadow);
  transition: all 0.5s ease;
}

.post-card:hover {
  box-shadow: var(--post-card-hover-shadow);
  transition: all 0.3s ease;
  transform: translate3D(0, -1px, 0);
}

.post-card-image-link {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.post-card-image {
  width: auto;
  height: 200px;
  background: var(--body-fg) no-repeat center center;
  background-size: cover;
}

.post-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post-card-content-link {
  display: block;
  position: relative;
  padding: var(--spacing) var(--spacing) 0;
  color: inherit;
}

.post-card-content-link:hover {
  text-decoration: none;
}

.post-card-categories {
  font-size: .8em;
  font-weight: bold;
  color: var(--body-color-light);
}

.post-card-title {
  margin-top: 0;
}

.post-card-meta {
  font-size: .8em;
  font-weight: bold;
  color: var(--body-color-light);
  padding: 0 var(--spacing) var(--spacing);
}

@media screen and (min-width: $screen-tablet) {
  .post-feed {
    margin: calc(var(--spacing) * 2) calc(0px - var(--spacing)) 0;
  }

  .post-card {
    margin: 0 var(--spacing) calc(var(--spacing) * 2);
  }

  .post-card:nth-child(6n+1) {
    flex: 1 1 100%;
    flex-direction: row;

    .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      border-radius: var(--border-radius) 0 0 var(--border-radius);
    }
  
    .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card-content:not(.no-cover) {
      flex: 0 1 357px;
    }
  }
}

@media screen and (min-width: $screen-laptop) {
  .post-feed {
    position: relative;
    top: -70px;
    margin-top: 0;
  }
}
