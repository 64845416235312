.site-header {
  color: var(--header-color);
  background: var(--header-bg) no-repeat center center;
  background-size: cover;
}

.site-header-shield {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.site-header-content {
  text-align: center;
  padding: calc(var(--spacing) * 4) 0;
}

.site-title {
  margin: 0;
}

.site-title a {
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.site-tagline {
  margin: 0;
  opacity: 0.8;
}

.nav {
  padding: calc(var(--spacing) * 2) 0;
  font-weight: bold;
  font-size: .8em;
  overflow-x: auto;
  white-space: nowrap;

  .active {
    color: var(--header-color);
  }
}

.nav-item {
  margin-right: var(--spacing);
  color: var(--header-color-dark);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--header-color);
  }
}

.site-header-small-content {
  display: flex;

  .site-title {
    padding: var(--spacing) 0;
    margin-right: var(--spacing);
    white-space: nowrap;
  }

  .nav {
    display: flex;
    align-items: center;
    padding: var(--spacing) 0;
  }
}

@media screen and (min-width: $screen-tablet) {
  .site-header-content {
    padding: calc(var(--spacing) * 6) 0;
  }
}

@media screen and (min-width: $screen-laptop) {
  .site-header-content {
    padding: calc(var(--spacing) * 8) 0;
  }

  .large-header .nav {
    position: relative;
    top: -70px;
  }
}
